//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueSlider from 'vue-slider-component';
import ViuInput from 'viu/components/viu-input/viu-input.vue';
import ViuLabel from 'viu/components/viu-label/viu-label.vue';

export default {
  name: 'SliderComponente',
  components: {
    VueSlider,
    ViuInput,
    ViuLabel
  },
  props: {
    value: {
      type: Number
    },
    propSlider: {
      type: Number
    },
    propInput: {
      type: Number
    },
    propMin: {
      type: Number
    },
    propMax: {
      type: Number
    },
    labelSlider: {
      type: String,
      default: ''
    },
    postInputSlider: {
      type: String,
      default: ''
    },
    inputLabel: {
      type: String,
      default: ''
    },
    postInputLabel: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    min: 0,
    max: 0,
    slider: null,
    inputModel: null
  }),
  watch: {
    slider: {
      inmediate: true,
      handler(val) {
        if (this.slider === '' || this.slider === null || this.slider === undefined) {
          this.slider = JSON.parse(JSON.stringify(this.min));
          return;
        }
        this.inputModel = JSON.parse(JSON.stringify(val));
      }
    },
    inputModel: {
      handler(val) {
        if (val === '' || val === undefined) {
          this.inputModel = null;
        }

        if (this.inputModel < this.min && this.inputModel !== null) {
          this.slider = Number(JSON.parse(JSON.stringify(this.min)));
          this.inputModel = Number(JSON.parse(JSON.stringify(this.min)));
        }

        if (this.inputModel > this.max && this.inputModel !== null) {
          this.slider = Number(JSON.parse(JSON.stringify(this.max)));
          this.inputModel = Number(JSON.parse(JSON.stringify(this.max)));
        }

        this.$emit('input', this.inputModel);
      }
    }
  },
  created() {
    this.min = JSON.parse(JSON.stringify(this.propMin));
    this.max = JSON.parse(JSON.stringify(this.propMax));
    this.slider = null;
    this.inputModel = null;
  }
};
